import React from "react";
import NavBar from "./NavBar";
import LeftMenu from "./LeftMenu";
import ArticleBox from "./ArticleBox";
import {
  getSeasons,
  getServerPass,
  getDefaultSeason,
  getArticlesFor
} from "../base";
import { highResImageURLFor } from "../helpers";

class App extends React.Component {
  state = {
    serverPass: "",
    selectedSeason: "",
    selectedSubCategory: "",
    allSeasons: [],
    allSeasonsAndSubCategories: {},
    collectionsForSelectedSeason: [],
    selectedCollection: "",
    allArticles: {},
    highResArticle: "",
    language: "en",
    multiLanguage: false,
    isLoading: false
  };

  updateLanguage = (language) => {
    this.setState({ language: language });
    localStorage.setItem("language", language);
    this.articlesFor(this.state.selectedSeason, this.state.selectedSubCategory);
  };

  updateHighResArticle = (articleNumber) => {
    this.setState({ highResArticle: articleNumber });
  };

  updateSelectedSeason = (selectedSeason) => {
    let selectedSubCategory = this.state.allSeasonsAndSubCategories[
      selectedSeason
    ][0];
    this.setState({
      selectedSeason: selectedSeason,
      selectedSubCategory: selectedSubCategory
    });
    this.articlesFor(selectedSeason, selectedSubCategory);
  };

  updateSelectedSubCategory = (selectedSubCategory) => {
    this.setState({ selectedSubCategory: selectedSubCategory });
    this.articlesFor(this.state.selectedSeason, selectedSubCategory);
  };

  updateSelectedCollection = (selectedCollection) => {
    this.setState({ selectedCollection: selectedCollection });
  };

  articlesFor = (selectedSeason, selectedSubCategory) => {
    this.setState({ isLoading: true });
    getArticlesFor(selectedSeason, selectedSubCategory).then((articles) => {
      this.setState({ isLoading: false });
      //Do something with the Firebase returned articles
      this.setState({ allArticles: articles });
      let collections = {};
      Object.entries(articles).forEach((element) => {
        //Check the language and see if multiple available
        if (element[1]["collection-fr"] !== undefined) {
          this.setState({ multiLanguage: true });
        } else {
          this.setState({ multiLanguage: false });
        }

        //Check the language and set header to find collection names in database
        let collection =
          this.state.language === "fr" &&
          element[1]["collection-fr"] !== undefined
            ? "collection-fr"
            : "collection";
        if (!Object.keys(collections).includes(element[1][collection])) {
          collections[element[1][collection]] = {
            sortOrder: element[1]["collectionSortOrder"]
          };
        }
      });
      const sortedCollections = Object.keys(collections).sort(function (
        sort1,
        sort2
      ) {
        return (
          collections[sort1]["sortOrder"] - collections[sort2]["sortOrder"]
        );
      });
      this.setState({
        collectionsForSelectedSeason: sortedCollections
      });
      this.updateSelectedCollection(sortedCollections[0]);
    });
  };

  componentDidMount() {
    const language = localStorage.getItem("language");
    if (language !== null) {
      this.setState({ language: language });
    }

    getServerPass()
      .then((serverPass) => {
        this.setState({ serverPass: serverPass });
      })
      .then(() => {
        if (
          this.state.serverPass.includes(sessionStorage.getItem("userPassword"))
        ) {
          getDefaultSeason().then((defaultSeason) => {
            const defaultSeasonName = defaultSeason.split("_", 1)[0];
            const defaultSubCategoryName = defaultSeason.slice(
              defaultSeason.indexOf("_") + 1,
              defaultSeason.length
            );

            // this.setState({
            //   selectedSeason: defaultSeasonName,
            //   selectedSubCategory: defaultSubCategoryName
            // });
          });
        }
      })
      .then(() => {
        if (
          this.state.serverPass.includes(sessionStorage.getItem("userPassword"))
        ) {
          getSeasons()
            .then((seasonsAndSubCategories) => {
              //take the seasons and subCategories and sort them by Firestore Sort Order
              const sortedSeasonsAndSubCategories = Object.keys(
                seasonsAndSubCategories
              ).sort(function (season1, season2) {
                return (
                  seasonsAndSubCategories[season1]["sortOrder"] -
                  seasonsAndSubCategories[season2]["sortOrder"]
                );
              });

              //Parse out the Season names along with their sub categories
              let seasons = new Set();
              let seasonsWithSubCategoriesDictionary = {};
              sortedSeasonsAndSubCategories.forEach((element, index) => {
                const seasonName = element.split("_", 1)[0];
                seasons.add(seasonName);
                const subCategory = element.slice(
                  element.indexOf("_") + 1,
                  element.length
                );

                if (index === 0) {
                  this.setState({
                    selectedSeason: seasonName,
                    selectedSubCategory: subCategory
                  });
                }

                if (sessionStorage.getItem("userPassword").includes("-full")) {
                  if (
                    seasonsWithSubCategoriesDictionary[seasonName] === undefined
                  ) {
                    seasonsWithSubCategoriesDictionary[seasonName] = [
                      subCategory
                    ];
                  } else {
                    seasonsWithSubCategoriesDictionary[seasonName].push(
                      subCategory
                    );
                  }
                } else if (subCategory.includes("Corporate")) {
                  if (
                    seasonsWithSubCategoriesDictionary[seasonName] === undefined
                  ) {
                    seasonsWithSubCategoriesDictionary[seasonName] = [
                      subCategory
                    ];
                  } else {
                    seasonsWithSubCategoriesDictionary[seasonName].push(
                      subCategory
                    );
                  }
                }
              });
              console.log(seasonsWithSubCategoriesDictionary);
              let cutoffSeasons = [];
              if (sessionStorage.getItem("userPassword").includes("-full")) {
                cutoffSeasons = Array.from(seasons).slice(0, 3);
              } else {
                cutoffSeasons = Array.from(seasons).slice(0, 2);
              }
              this.setState({
                //cut off earlier seasons
                allSeasons: cutoffSeasons,
                allSeasonsAndSubCategories: seasonsWithSubCategoriesDictionary
              });
            })
            .then(() => {
              this.articlesFor(
                this.state.selectedSeason,
                this.state.selectedSubCategory
              );
            });
        }
      });
  }

  render() {
    let filteredSortedArticles = [];
    let articlesArray = [];
    Object.entries(this.state.allArticles).forEach((element) => {
      articlesArray.push(element[1]);
    });
    filteredSortedArticles = articlesArray.filter((article) => {
      //Check the language and set header to find collection names in database
      let collection =
        this.state.language === "fr" && article["collection-fr"] !== undefined
          ? "collection-fr"
          : "collection";

      return (
        article[collection] === this.state.selectedCollection &&
        article.sizes !== article.soldOutSizes
      );
    });

    filteredSortedArticles = filteredSortedArticles.sort(function (
      article1,
      article2
    ) {
      return (
        article1["articleSortOrderWithinCollection"] -
        article2["articleSortOrderWithinCollection"]
      );
    });

    return (
      <React.Fragment>
        <NavBar
          selectedSeason={this.state.selectedSeason}
          selectedSubCategory={this.state.selectedSubCategory}
          allSeasons={this.state.allSeasons}
          allSeasonsAndSubCategories={this.state.allSeasonsAndSubCategories}
          updateSelectedSeason={this.updateSelectedSeason}
          updateSelectedSubCategory={this.updateSelectedSubCategory}
          collections={this.state.collectionsForSelectedSeason}
          updateSelectedCollection={this.updateSelectedCollection}
          selectedCollection={this.state.selectedCollection}
          updateLanguage={this.updateLanguage}
          language={this.state.language}
          multiLanguage={this.state.multiLanguage}
        />
        <div className='columns app' style={{ display: "flex" }}>
          <div
            className='column is-hidden-touch leftMenu is-narrow-desktop'
            style={{
              overflowY: "auto",
              flexGrow: 0
            }}
          >
            <LeftMenu
              collections={this.state.collectionsForSelectedSeason}
              updateSelectedCollection={this.updateSelectedCollection}
              selectedCollection={this.state.selectedCollection}
            />
          </div>
          <div
            className='column productBody has-background-white is-flex'
            style={{ flexWrap: "wrap", flexGrow: 1, overflowY: "auto" }}
          >
            {filteredSortedArticles.map((article) => {
              return (
                <ArticleBox
                  articleInfo={article}
                  language={this.state.language}
                  updateHighResArticle={this.updateHighResArticle}
                  key={article["articleNumber"]}
                />
              );
            })}
          </div>
        </div>
        <div
          className={`modal ${
            this.state.highResArticle === "" ? "" : "is-active"
          }`}
        >
          <div className='modal-background'></div>
          <div className='modal-content'>
            <img
              src={highResImageURLFor(this.state.highResArticle, articlesArray)}
              alt=''
              style={{ maxHeight: "100%" }}
            />
          </div>
          <button
            className='modal-close is-large'
            aria-label='close'
            onClick={() => this.updateHighResArticle("")}
          ></button>
        </div>
        <div className={`modal ${this.state.isLoading ? "is-active" : ""}`}>
          <div className='modal-background'></div>
          <div className='modal-content'>
            <div className='container' style={{ textAlign: "center" }}>
              <p style={{ fontSize: "20px", color: "white", opacity: "0.7" }}>
                Loading...
              </p>
              {/* <progress class='progress is-large is-dark' max='100'>
                60%
              </progress> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
